import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import SoflopLogo from "../assets/soflop_logo.png";

interface Props {
  window?: Window;
}

const drawerWidth = 240;

export default function CustomAppBar(props: Props) {
  const { t } = useTranslation();

  const navItems = ["home", "features", "pricing", "FAQ", "contact"];

  const scrollToSection = (sectionId: string) => {
    const elementRef = window!.document.getElementById(sectionId)!;

    console.log("Scroll to section called!");
    window!.scrollTo({
      top: elementRef.offsetTop + 1,
      behavior: "smooth",
    });
  };

  const theme = useTheme();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  let homeTop = 0;
  let featuresTop = 0;
  let pricingTop = 0;
  let faqTop = 0;
  let contactTop = 0;

  const [indexSelected, setSelectedIndex] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  let limit =
    window!.document.documentElement.scrollHeight -
    window!.document.documentElement.clientHeight;

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window!.scrollY;
    setScrollPosition(position);

    if (position <= featuresTop) {
      setSelectedIndex(0);
    } else if (position <= pricingTop) {
      setSelectedIndex(1);
    } else if (position <= faqTop) {
      setSelectedIndex(2);
    } else if (position <= contactTop) {
      setSelectedIndex(3);
    } else {
      setSelectedIndex(4);
    }
  };

  function checkSelectionDisplay(title: string) {
    var index = navItems.indexOf(title);

    if (index == indexSelected) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    window!.addEventListener("scroll", handleScroll, { passive: true });

    homeTop = window!.document.getElementById("home")!.offsetTop!;
    featuresTop = window!.document.getElementById("features")!.offsetTop!;
    pricingTop = window!.document.getElementById("pricing")!.offsetTop!;
    faqTop = window!.document.getElementById("FAQ")!.offsetTop!;
    contactTop = window!.document.getElementById("contact")!.offsetTop!;

    limit =
      window!.document.documentElement.scrollHeight -
      window!.document.documentElement.clientHeight;

    return () => {
      window!.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}></Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => scrollToSection(item)}
            >
              <ListItemText primary={t(item)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box>
      <Box
        zIndex={10}
        width={"100%"}
        height={"5px"}
        position={"fixed"}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Box
          top={0}
          left={0}
          width={`${
            scrollPosition / limit >= 0 ? (scrollPosition / limit) * 100 : 0.0
          }%`}
          height={"5px"}
          sx={{
            backgroundColor: `${theme.palette.secondary.main}`,
          }}
        ></Box>
      </Box>
      <Box top={10} sx={{ display: "flex", justifyContent: "space-between" }}>
        <CssBaseline />
        <AppBar
          elevation={0}
          component="nav"
          color="transparent"
          sx={{
            top: "5px",
            paddingLeft: {
              xs: "10px",
              md: "80px",
              lg: "160px",
            },
            paddingRight: {
              xs: "10px",
              md: "80px",
              lg: "160px",
            },
            boxShadow:
              scrollPosition >= 4.0
                ? "0px 6px 6px -3px rgba(0, 0, 0, 0.01), 0px 10px 14px 1px rgba(0, 0, 0, 0.01), 0px 4px 18px 3px rgba(0, 0, 0, 0.01)"
                : "none",
            backgroundColor: `${theme.palette.background.default}`,
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box component={"img"} src={SoflopLogo} height={"50px"}></Box>

            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              {navItems.map((item) => (
                <Button
                  key={item}
                  sx={{
                    backgroundColor: `${
                      checkSelectionDisplay(item)
                        ? "secondary.main"
                        : "transparent"
                    }`,
                    marginLeft: "4px",
                    marginRight: "4px",
                    color: `${checkSelectionDisplay(item) ? "white" : "black"}`,
                    textTransform: "capitalize",
                    fontWeight: checkSelectionDisplay(item) ? 600 : 500,
                  }}
                  onClick={() => scrollToSection(item)}
                >
                  {t(item)}
                </Button>
              ))}
            </Box>

            <Button
              variant="contained"
              color="secondary"
              sx={{ textTransform: "uppercase", fontWeight: 600, fontSize: 12 }}
              onClick={() => {
                window?.open(`https://wa.me/34604141160`);
              }}
            >
              {t("contactSales")}
            </Button>
          </Toolbar>
        </AppBar>

        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </Box>
  );
}
