import { Box, Button, Typography, useTheme } from "@mui/material";
import BuildWebsiteNowBackground from "../assets/build_website_now.jpg";
import { useTranslation } from "react-i18next";

export default function StartNow() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box position={"relative"} width={"100%"} marginTop={10} height={"700px"}>
      <Box
        component={"img"}
        position={"absolute"}
        width={"100%"}
        height={"700px"}
        top={0}
        left={0}
        sx={{
          backgroundImage: `url(${BuildWebsiteNowBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Box
        component={"section"}
        position={"absolute"}
        width={"100%"}
        top={0}
        left={0}
        height={"700px"}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
        alignContent={"center"}
      >
        <Typography
          fontWeight={600}
          fontSize={55}
          marginTop={2}
          color={"white"}
          textAlign={"center"}
          maxWidth={"450px"}
          marginLeft={"auto"}
          marginRight={"auto"}
        >
          <span
            style={{
              color: `${theme.palette.lightGrayText}`,
            }}
          >
            {t("startNow")}
          </span>{" "}
          {t("powerYourBusinessByAIToday")}
        </Typography>

        {/* <Button
          variant="contained"
          color={"secondary"}
          sx={{
            marginTop: "48px",
            fontWeight: 600,
            fontSize: 14,
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
          disabled={true}
        >
          {t("empower")}
        </Button> */}
      </Box>
    </Box>
  );
}
