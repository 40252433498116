import { Box, Button, Typography, useTheme } from "@mui/material";
import SoftwareDevelopmentWallpaper from "../assets/software_development_wallpaper.png";
import { useTranslation } from "react-i18next";

export default function CustomSoftware() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      component={"section"}
      position={"relative"}
      height={"350px"}
      marginTop={15}
    >
      <Box
        component={"img"}
        position={"absolute"}
        width={"100%"}
        height={"350px"}
        top={0}
        left={0}
        sx={{
          backgroundImage: `url(${SoftwareDevelopmentWallpaper})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>

      <Box
        component={"section"}
        position={"absolute"}
        width={"100%"}
        top={0}
        left={0}
        height={"350px"}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
        alignContent={"center"}
      >
        <Typography
          textTransform={"uppercase"}
          color={`lightGrayText`}
          fontWeight={600}
          fontSize={18}
        >
          {t("custom")}
        </Typography>

        <Typography
          fontWeight={600}
          fontSize={40}
          marginTop={2}
          color={"white"}
        >
          <span
            style={{
              color: `${theme.palette.secondary.main}`,
            }}
          >
            {t("softwareDevelopment").split(" ")[0]}
          </span>{" "}
          {t("softwareDevelopment").split(" ")[1]}
          {` ${
            t("softwareDevelopment").split(" ")[2] != undefined
              ? t("softwareDevelopment").split(" ")[2]
              : ""
          }`}
        </Typography>

        <Button
          variant="contained"
          color={"secondary"}
          sx={{
            marginTop: "32px",
            fontWeight: 600,
            fontSize: 14,
          }}
          onClick={() => {
            window?.open(`https://wa.me/34604141160`);
          }}
        >
          {t("getStarted")}
        </Button>
      </Box>
    </Box>
  );
}
