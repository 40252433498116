import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import HomeHero from "../assets/home_hero.png";
import CarpenterRoundedIcon from "@mui/icons-material/CarpenterRounded";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";
import { Palette } from "@mui/icons-material";

interface Props {
  window?: () => Window;
}

export default function Presentation(props: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      id="home"
      container
      spacing={2}
      paddingTop={20}
      paddingLeft={{ xs: 4, md: 10, lg: 20 }}
      paddingRight={{ xs: 4, md: 10, lg: 20 }}
      paddingBottom={5}
      sx={{
        backgroundColor: `${theme.palette.lightGray}`,
      }}
    >
      <Grid item sm={12} md={6} paddingRight={3}>
        {i18n.language == "en" ? (
          <Typography variant="h2" textAlign={"start"} fontWeight={600}>
            <span
              style={{
                color: `${theme.palette.secondary.main}`,
              }}
            >
              {t("aiIntegratedSoftwareSolutions").split(" ")[0]}{" "}
              {t("aiIntegratedSoftwareSolutions").split(" ")[1]}
            </span>
            {t("aiIntegratedSoftwareSolutions").substring(13)}
          </Typography>
        ) : (
          <Typography variant="h2" textAlign={"start"} fontWeight={600}>
            {t("aiIntegratedSoftwareSolutions").substring(0, 22)}{" "}
            <span
              style={{
                color: `${theme.palette.secondary.main}`,
              }}
            >
              {t("aiIntegratedSoftwareSolutions").substring(22)}
            </span>
          </Typography>
        )}

        <Typography textAlign={"start"} marginTop={7} color={"mediumLightText"}>
          {t("presentationDescription2")}
        </Typography>

        <Box display={"flex"} justifyContent={"start"} marginTop={4}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              alignSelf: "start",
              textTransform: "uppercase",
              fontWeight: 600,
              height: "50px",
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
            onClick={() => {
              window.open(`https://wa.me/34604141160`);
            }}
          >
            {t("contactSales")}
          </Button>
        </Box>
      </Grid>

      <Grid item sm={12} md={6}>
        <Box
          component="img"
          maxWidth={"800px"}
          sx={{
            width: "140%",
          }}
          alt="All the website options."
          src={HomeHero}
        />
      </Grid>
    </Grid>
  );
}
