import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

interface Props {
  questions: FAQData[];
}

export interface FAQData {
  title: string;
  content: string;
}

export default function AccordionFAQ(props: Props) {
  return (
    <div>
      {props.questions.map((e) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              fontWeight: 600,
              height: "80px",
            }}
            key={e.title}
          >
            {e.title}
          </AccordionSummary>
          <AccordionDetails sx={{ textAlign: "start" }}>
            {e.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
