import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WebAssetRoundedIcon from "@mui/icons-material/WebAssetRounded";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";

export default function ContactOptions() {
  const theme = useTheme();
  return (
    <Grid container width={"100%"} marginTop={10}>
      <Grid item xs={12} sm={6}>
        <Box>
          <Box display={"flex"}>
            <Box
              sx={{
                backgroundColor: `${theme.palette.mediumBackground}`,
                width: "40px",
                height: "40px",
                borderRadius: "30px",
                verticalAlign: "center",
              }}
              display={"flex"}
              justifyContent={"center"}
              alignSelf={"center"}
              padding={"7px 0px"}
            >
              <LocationOnRoundedIcon
                sx={{
                  color: `${theme.palette.secondary.main}`,
                }}
              />
            </Box>

            <Typography
              marginTop={"7px"}
              marginLeft={2}
              fontWeight={550}
              textAlign={"start"}
            >
              Dirección
            </Typography>
          </Box>

          <Typography fontSize={12} marginLeft={"56px"} textAlign={"start"}>
            Valencia, España
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Box display={"flex"}>
            <Box
              sx={{
                backgroundColor: `${theme.palette.mediumBackground}`,
                width: "40px",
                height: "40px",
                borderRadius: "30px",
                verticalAlign: "center",
              }}
              display={"flex"}
              justifyContent={"center"}
              padding={"7px 0px"}
            >
              <EmailRoundedIcon
                sx={{
                  color: `${theme.palette.secondary.main}`,
                }}
              />
            </Box>

            <Typography
              marginTop={"7px"}
              marginLeft={2}
              fontWeight={550}
              textAlign={"start"}
            >
              Correo
            </Typography>
          </Box>

          <Typography fontSize={12} marginLeft={"56px"} textAlign={"start"}>
            support@softopsoftware.com
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        marginTop={{
          xs: 4,
          sm: 0,
        }}
      >
        <Box>
          <Box display={"flex"}>
            <Box
              sx={{
                backgroundColor: `${theme.palette.mediumBackground}`,
                width: "40px",
                height: "40px",
                borderRadius: "30px",
                verticalAlign: "center",
              }}
              display={"flex"}
              justifyContent={"center"}
              alignSelf={"center"}
              padding={"7px 0px"}
            >
              <WebAssetRoundedIcon
                sx={{
                  color: `${theme.palette.secondary.main}`,
                }}
              />
            </Box>

            <Typography
              marginTop={"7px"}
              marginLeft={2}
              fontWeight={550}
              textAlign={"start"}
            >
              Redes Sociales
            </Typography>
          </Box>

          <Box marginLeft={"56px"} display={"flex"}>
            {/*<FacebookIcon sx={{ width: "20px", height: "20px" }} />*/}
            {/*<XIcon sx={{ width: "20px", height: "20px", marginLeft: "10px" }} />*/}
            <Button
              sx={{
                padding: "0px",
                minWidth: "0px",
              }}
              onClick={() => {
                window.open("https://www.instagram.com/softopsoftware/");
              }}
            >
              <InstagramIcon
                sx={{ width: "20px", height: "20px", margin: "0px" }}
              />
            </Button>
            {/*<LinkedInIcon
              sx={{ width: "20px", height: "20px", marginLeft: "10px" }}
            />*/}
          </Box>
        </Box>

        <Box marginTop={3}>
          <Box display={"flex"}>
            <Box
              sx={{
                backgroundColor: `${theme.palette.mediumBackground}`,
                width: "40px",
                height: "40px",
                borderRadius: "30px",
                verticalAlign: "center",
              }}
              display={"flex"}
              justifyContent={"center"}
              padding={"7px 0px"}
            >
              <PhoneEnabledRoundedIcon
                sx={{
                  color: `${theme.palette.secondary.main}`,
                }}
              />
            </Box>

            <Typography
              marginTop={"7px"}
              marginLeft={2}
              fontWeight={550}
              textAlign={"start"}
            >
              Teléfono
            </Typography>
          </Box>

          <Typography fontSize={12} marginLeft={"56px"} textAlign={"start"}>
            +34 604 14 11 60
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
