import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTranslation } from "react-i18next";
import SoflopLogo from "../assets/soflop_logo.png";

export default function CustomFooter() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      component={"footer"}
      width={"100%"}
      minHeight={"300px"}
      sx={{
        backgroundColor: `${theme.palette.footerBackground}`,
      }}
      paddingLeft={{ xs: 4, md: 10, lg: 20 }}
      paddingRight={{ xs: 4, md: 10, lg: 20 }}
      paddingTop={10}
      paddingBottom={5}
    >
      <Grid container>
        <Grid item xs={12} md={4} display={"flex"} justifyContent={"start"}>
          <Box component={"img"} src={SoflopLogo} height={"40px"} />
        </Grid>

        <Grid item xs={12} md={8}>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={{
              xs: "start",
              md: "end",
            }}
            marginTop={{
              xs: 1,
              sm: 0,
            }}
          >
            <Typography
              fontSize={{
                xs: 10,
                sm: 14,
              }}
              sx={{
                marginRight: { xs: "0px", md: "20px" },
              }}
            >
              {t("webDevelopment")}
            </Typography>

            <Typography
              fontSize={{
                xs: 10,
                sm: 14,
              }}
              sx={{ marginRight: "10px", marginLeft: "20px" }}
            >
              {t("aiIntegrations")}
            </Typography>

            <Typography
              fontSize={{
                xs: 10,
                sm: 14,
              }}
              sx={{ marginRight: "10px", marginLeft: "20px" }}
            >
              {t("mobileDevelopment")}
            </Typography>

            <Typography
              fontSize={{
                xs: 10,
                sm: 14,
              }}
              sx={{ marginRight: "10px", marginLeft: "20px" }}
            >
              {t("gameDevelopment")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "32px",
        }}
      />

      <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
        <Grid container width={"100%"}>
          <Grid item xs={12} sm={6}>
            <Typography
              fontSize={12}
              color={"gray"}
              minWidth={"250px"}
              textAlign={"start"}
            >
              © 2024 Softop. {t("allRightsReserved")}.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={{
                xs: "start",
                sm: "end",
              }}
              marginTop={{
                xs: 1,
                sm: 0,
              }}
            >
              {/*<FacebookIcon sx={{ marginRight: "10px" }} />*/}
              {/*<XIcon sx={{ marginRight: "10px", marginLeft: "20px" }} />*/}
              <Button
                sx={{
                  minWidth: "0px",
                }}
                onClick={() => {
                  window.open("https://www.instagram.com/softopsoftware/");
                }}
              >
                <InstagramIcon />
              </Button>

              {/*<LinkedInIcon sx={{ marginRight: "10px", marginLeft: "20px" }} />*/}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
