import { Box, Grid, Typography, useTheme } from "@mui/material";
import BusinessPageTemplate from "../components/BusinessPageTemplate";
import { useTranslation } from "react-i18next";
import CustomChat from "../components/CustomChat";

interface Props {
  window?: () => Window;
}

export default function Highlights(props: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid
      id="features"
      container
      paddingTop={10}
      marginBottom={20}
      paddingLeft={{ xs: 4, md: 10, lg: 20 }}
      paddingRight={{ xs: 4, md: 10, lg: 20 }}
    >
      <Grid item xs={12} sm={12} lg={5} paddingRight={5}>
        <Typography
          fontWeight={600}
          color={"gray"}
          textAlign={"start"}
          textTransform={"uppercase"}
        >
          {t("aiPowered")}
        </Typography>

        <Typography
          fontWeight={600}
          fontSize={{
            xs: 40,
            sm: 50,
          }}
          textAlign={"start"}
          marginTop={3}
        >
          {t("toolsThatMassivelyImproveYourBusiness")}
        </Typography>

        <Typography
          color={"gray"}
          fontSize={16}
          textAlign={"start"}
          marginTop={3}
          paddingBottom={5}
        >
          {t("aPersonalizeAIAgentForYourBusiness")}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        lg={7}
        sx={{
          borderRadius: "25px",
          borderStyle: "dashed",
          borderWidth: "1.5px",
          borderColor: `${theme.palette.lightOutline}`,
        }}
      >
        <CustomChat />
      </Grid>
    </Grid>
  );
}
