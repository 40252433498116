import "./App.css";
import CustomAppBar from "./components/CustomAppBar";
import Presentation from "./pages/Presentation";
import Features from "./pages/Features";
import Highlights from "./pages/Highlights";
import Services from "./pages/Services";
import CustomSoftware from "./pages/CustomSoftware";
import StandardPlans from "./pages/StandardPlans";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import StartNow from "./pages/StartNow";
import CustomFooter from "./pages/CustomFooter";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import Highlights2 from "./pages/Highlights2";
import ContactForm from "./pages/contact/ContactForm";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-wq29xy_Xoy1zU7V8iXV0H4FfecfjFRQ",
  authDomain: "crystaleyelanding.firebaseapp.com",
  projectId: "crystaleyelanding",
  storageBucket: "crystaleyelanding.appspot.com",
  messagingSenderId: "435490833826",
  appId: "1:435490833826:web:39bf291d06c2d7d33edefb",
  measurementId: "G-8HBXTXY8ES",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);

function App() {
  return (
    <div
      className="App"
      style={{
        overflow: "clip",
      }}
    >
      <header></header>

      <body>
        <CustomAppBar window={window} />
        <Presentation />
        <Features />
        <Highlights />
        <Highlights2 />
        <Services />
        <CustomSoftware />
        <StandardPlans />
        <FAQ />
        <Contact />
        <StartNow />
        <CustomFooter />
      </body>
    </div>
  );
}

export default App;
