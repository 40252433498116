import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

export default function ContactForm() {
  const theme = useTheme();

  const [sendingMessage, setSendingMessage] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSendMessage = () => {
    /*setSendingMessage(true);*/
    window.open(
      `mailto:support@softopsoftware.com?subject=Información de cliente ${
        name + " " + lastName
      }&body=${message}`
    );
  };

  function isButtonDisabled() {
    return name == "" || lastName == "" || message == "";
  }

  return (
    <Box width={"100%"}>
      <Typography variant="h4" fontWeight={600} fontSize={40}>
        Contacto
      </Typography>

      <Typography variant="body1" marginTop={4}>
        ¡Estamos aquí para ayudarte! Ya sea que tengas preguntas, necesites
        soporte o quieras brindarnos tus comentarios, nos encantaría saber de
        ti. No dudes en ponerte en contacto con nosotros a través de cualquiera
        de los métodos a continuación, y nuestro equipo te responderá lo antes
        posible.
      </Typography>

      <Grid container marginTop={5}>
        <Grid item xs={6} paddingRight={1}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel
              htmlFor="my-input"
              sx={{
                marginTop: "10px",
              }}
            >
              Nombre
            </InputLabel>
            <Input
              disableUnderline={true}
              id="my-input"
              aria-describedby="my-helper-text"
              disabled={sendingMessage}
              onChange={(newName) => {
                setName(newName.target.value);
              }}
              sx={{
                borderRadius: "3px",

                backgroundColor: `${theme.palette.mediumBackground}`,
                padding: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} paddingLeft={1}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel
              htmlFor="my-input2"
              sx={{
                marginTop: "10px",
              }}
            >
              Apellido
            </InputLabel>
            <Input
              disableUnderline={true}
              id="my-input2"
              aria-describedby="my-helper-text"
              disabled={sendingMessage}
              onChange={(newLastName) => {
                setLastName(newLastName.target.value);
              }}
              sx={{
                borderRadius: "3px",
                backgroundColor: `${theme.palette.mediumBackground}`,
                padding: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/*<Grid container marginTop={2}>
        <Grid item xs={12}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel
              htmlFor="my-input"
              sx={{
                marginTop: "10px",
              }}
            >
              Correo Electronico
            </InputLabel>
            <Input
              disableUnderline={true}
              id="my-input"
              aria-describedby="my-helper-text"
              disabled={sendingMessage}
              onChange={(newEmail) => {
                setEmail(newEmail.target.value);
              }}
              sx={{
                borderRadius: "3px",
                backgroundColor: `${theme.palette.mediumBackground}`,
                padding: "5px",
              }}
            />
          </FormControl>
        </Grid>
            </Grid>*/}

      <Grid container marginTop={2}>
        <Grid item xs={12}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel
              htmlFor="my-input"
              sx={{
                marginTop: "10px",
              }}
            >
              Mensaje
            </InputLabel>
            <Input
              disableUnderline={true}
              id="my-input"
              aria-describedby="my-helper-text"
              multiline={true}
              minRows={3}
              disabled={sendingMessage}
              onChange={(newMessage) => {
                setMessage(newMessage.target.value);
              }}
              sx={{
                borderRadius: "3px",
                backgroundColor: `${theme.palette.mediumBackground}`,
                padding: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="secondary"
        sx={{
          width: "100%",
          marginTop: "40px",
          minHeight: "50px",
        }}
        disabled={isButtonDisabled()}
        onClick={handleSendMessage}
      >
        {sendingMessage ? <CircularProgress /> : "Enviar"}
      </Button>
    </Box>
  );
}
