import React from "react";
import { createStyles, makeStyles } from "@mui/styles";

import { MessageLeft, MessageRight } from "./Message";
import { Paper, Theme } from "@mui/material";
import { TextInput } from "./TextInput";
import BotIcon from "../assets/bot_profile.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      margin: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    messagesBody: {
      width: "100%",
      margin: 10,
      overflowY: "scroll",
    },
  })
);

export default function CustomChat() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <div id="style-1" className={classes.messagesBody}>
          <MessageLeft
            message="Hola, ¿en que puedo ayudarte?, haz cualquer pregunta y te asistiré lo mejor que pueda."
            timestamp="02/20 12:00"
            photoURL={BotIcon}
            displayName="Agente"
          />
          <MessageLeft
            message="Soy un agente avanzado de inteligencia artificial, puedo ayudarte con cualquier duda o información que necesites relativo a los productos, servicios que la empresa ofrece."
            timestamp="02/20 12:01"
            photoURL={BotIcon}
            displayName="Agente"
          />
          <MessageRight
            message="De que manera puedes asistirme?"
            timestamp="02/20 12:04"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="まさりぶ"
          />

          <MessageLeft
            message="Puedo proporcionar asistentes virtuales y chatbots para atención 24/7, analizar sentimientos y opiniones de clientes, automatizar procesos como la gestión de tickets y el seguimiento de casos, personalizar recomendaciones, predecir necesidades futuras, optimizar recursos humanos, interpretar encuestas y feedback, asistir en llamadas telefónicas, gestionar consultas en redes sociales, y detectar problemas recurrentes para sugerir mejoras, todo ello mejorando la eficiencia y la satisfacción del cliente."
            timestamp="02/20 12:05"
            photoURL={BotIcon}
            displayName="Agente"
          />
        </div>
      </div>
    </div>
  );
}
