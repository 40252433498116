import { Box, Grid, Typography, useTheme } from "@mui/material";

import Bot from "../assets/bot.png";
import { useTranslation } from "react-i18next";

export default function Features() {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        backgroundColor: "white",
      }}
    >
      <Grid
        item
        xs={12}
        marginLeft={{ xs: 4, md: 10, lg: 20 }}
        marginRight={{ xs: 4, md: 10, lg: 20 }}
        padding={{ xs: 4, sm: 8 }}
        sx={{
          marginTop: "100px",
          marginBottom: "100px",
          backgroundColor: `${theme.palette.mediumBackground}`,
          borderRadius: "25px",
        }}
      >
        <Box
          component={"img"}
          src={Bot}
          width={{
            xs: "80%",
            sm: "400px",
          }}
        ></Box>

        <Typography
          textTransform={"uppercase"}
          color={`lightGrayText`}
          fontWeight={600}
          fontSize={18}
        >
          {t("completeAutomation")}
        </Typography>

        <Typography fontWeight={600} fontSize={40} marginTop={2}>
          {t("reduceCostsAndIncreaseProductivity").split(" ")[0]}{" "}
          <span
            style={{
              color: `${theme.palette.secondary.main}`,
            }}
          >
            {t("reduceCostsAndIncreaseProductivity").split(" ")[1]}
          </span>{" "}
          {t("reduceCostsAndIncreaseProductivity").split(" ")[2]}{" "}
          <span
            style={{
              color: `${theme.palette.secondary.main}`,
            }}
          >
            {t("reduceCostsAndIncreaseProductivity").split(" ")[3]}
          </span>{" "}
          {t("reduceCostsAndIncreaseProductivity").split(" ")[4]}
        </Typography>

        <Typography
          color={`gray`}
          fontSize={18}
          marginTop={3}
          marginLeft={{
            xs: 5,
            md: 20,
          }}
          marginRight={{
            xs: 5,
            md: 20,
          }}
        >
          {t("takeAdvantageOfTheAdvanceAIModel")}
        </Typography>
      </Grid>
    </Grid>
  );
}
