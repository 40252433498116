import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./i18n/config";

declare module "@mui/material/styles" {
  interface Palette {
    mediumLightText: string;
    mediumBackground: string;
    lightGray: string;
    lightGrayText: string;
    lightOutline: string;
    popular: string;
    footerBackground: string;
  }
  interface PaletteOptions {
    mediumLightText: string;
    mediumBackground: string;
    lightGray: string;
    lightGrayText: string;
    lightOutline: string;
    popular: string;
    footerBackground: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    mediumLightText: true;
    mediumBackground: true;
    lightGray: true;
    lightGrayText: true;
    lightOutline: true;
    popular: true;
    footerBackground: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#00000",
      contrastText: "#000",
    },
    secondary: {
      main: "#29abe2",
      contrastText: "#fff",
    },
    mediumLightText: "#616161",
    mediumBackground: "#f5f5f5",
    lightGray: "#fafafa",
    lightGrayText: "#c4c4c4",
    lightOutline: "#e8e8e8",
    popular: "#84a1ff",
    footerBackground: "#e1e1e1",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
