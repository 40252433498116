import { Box, Typography } from "@mui/material";
import AccordionFAQ, { FAQData } from "../components/AccordionFAQ";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../App";

export default function FAQ() {
  useEffect(() => {
    getData();
  }, []);

  const [faqs, setFaqs] = useState<FAQData[]>([]);

  async function getData() {
    const faqSnapshot = await getDocs(
      query(collection(db, "faqs"), orderBy("index", "asc"))
    );

    const faqResult: FAQData[] = [];

    for (let i = 0; i < faqSnapshot.docs.length; i++) {
      var doc = faqSnapshot.docs[i];
      faqResult.push({
        title: doc.data().question["es"],
        content: doc.data().answer["es"],
      });
    }

    setFaqs(faqResult);
  }

  const { t } = useTranslation();
  return (
    <Box
      id="FAQ"
      component={"section"}
      paddingBottom={10}
      paddingLeft={{ xs: 4, md: 10, lg: 20 }}
      paddingRight={{ xs: 4, md: 10, lg: 20 }}
    >
      <Typography fontWeight={600} fontSize={40} marginTop={2}>
        {t("frequentlyAskedQuestions")}
      </Typography>

      <Box
        marginTop={10}
        maxWidth={"800px"}
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <AccordionFAQ questions={faqs} />
      </Box>
    </Box>
  );
}
