import { createStyles, makeStyles } from "@mui/styles";

import { Avatar, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "30px",
      marginBottom: "30px",
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "20px",
      backgroundColor: `${theme.palette.secondary.main}`,
      width: "60%",
      color: "white",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",

      borderRadius: "10px",

      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        top: "-1px",
        left: "-17px",
      },
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "20px",
      backgroundColor: "#e1e1e1",
      width: "60%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      borderRadius: "10px",

      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "5px",
      right: "20px",
    },

    orange: {
      color: "black",
      backgroundColor: "lightGray",
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      marginLeft: "10px",
      textAlign: "left",
      fontWeight: 500,
      fontSize: "12px",
    },
  })
);

//avatarが左にあるメッセージ（他人）
export const MessageLeft = (props: {
  message: any;
  timestamp: any;
  photoURL: any;
  displayName: any;
}) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const classes = useStyles();
  return (
    <>
      <div className={classes.messageRow}>
        <Avatar
          alt={displayName}
          className={classes.orange}
          src={photoURL}
        ></Avatar>
        <div>
          <div className={classes.messageBlue}>
            <div>
              <p className={classes.messageContent}>{message}</p>
            </div>
            <div className={classes.messageTimeStampRight}>{timestamp}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MessageRight = (props: {
  message: any;
  timestamp: any;
  photoURL: any;
  displayName: any;
}) => {
  const classes = useStyles();
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageOrange}>
        <p className={classes.messageContent}>{message}</p>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};
