import { Box, Grid, Typography, useTheme } from "@mui/material";
import PricingCard from "../components/PricingCard";
import { db } from "../App";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PackageType {
  name: string;
  description: string;
  isPopular: boolean;
  price: number;
  maintenancePrice: number;
  stripeProductUrl: string;
  features: string[];
}

export default function StandardPlans() {
  const { t } = useTranslation();

  useEffect(() => {
    getPlans();
  }, []);

  const [packages, setPackages] = useState<PackageType[]>([]);

  async function getPlans() {
    const packagesSnapshot = await getDocs(
      query(collection(db, "packages"), orderBy("index", "asc"))
    );

    const instancePackages: PackageType[] = [];

    for (let i = 0; i < packagesSnapshot.docs.length; i++) {
      var doc = packagesSnapshot.docs[i];
      const featuresSnapshot = await getDocs(collection(doc.ref, "attributes"));
      instancePackages.push({
        name: doc.data().name["es"],
        description: doc.data().description["es"],
        isPopular: doc.data().isPopular,
        price: doc.data().price,
        maintenancePrice: doc.data().maintenancePrice,
        stripeProductUrl: doc.data().stripeProductUrl,
        features: featuresSnapshot.docs.map((e) => e.data().name["es"]),
      });

      console.log(`Package loaded: ${doc.data().name["es"]}`);
    }

    setPackages(instancePackages);
  }

  const theme = useTheme();
  return (
    <Box
      id="pricing"
      component={"section"}
      marginTop={10}
      paddingBottom={2}
      paddingLeft={{ xs: 4, md: 10, lg: 20 }}
      paddingRight={{ xs: 4, md: 10, lg: 20 }}
    >
      <Box>
        <Typography
          textTransform={"uppercase"}
          color={`lightGrayText`}
          fontWeight={600}
          fontSize={18}
        >
          {t("pricing")}
        </Typography>

        <Typography fontWeight={600} fontSize={40} marginTop={2}>
          {t("standardPackages").split(" ")[0]}{" "}
          <span
            style={{
              color: `${theme.palette.secondary.main}`,
            }}
          >
            {t("standardPackages").split(" ")[1]}
          </span>{" "}
        </Typography>

        <Grid container marginTop={8} marginBottom={20}>
          {packages.map((e) => (
            <Grid item xs={12} sm={6} md={4} padding={1} marginBottom={2}>
              <PricingCard
                key={e.name}
                packageName={e.name}
                description={e.description}
                price={e.price}
                maintenancePrice={e.maintenancePrice}
                features={e.features}
                licenceUrl={""}
                stripeProductUrl={e.stripeProductUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
