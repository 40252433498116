import { Box, Grid, Typography, useTheme } from "@mui/material";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import WordpressIcon from "../assets/wordpress_icon.png";
import ShopifyStore from "../assets/shopify.png";
import SEO from "../assets/seo_icon.png";
import MobileIcon from "../assets/mobile_icon.png";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import GamesOutlinedIcon from "@mui/icons-material/GamesOutlined";
import { useTranslation } from "react-i18next";

export default function Services() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      container
      marginTop={5}
      paddingLeft={{ xs: 4, md: 10, lg: 20 }}
      paddingRight={{ xs: 4, md: 10, lg: 20 }}
    >
      <Grid sm={12} lg={5} paddingRight={5}>
        <Typography fontWeight={600} color={"gray"} textAlign={"start"}>
          {t("servicesHighlights")}
        </Typography>

        <Typography
          fontWeight={600}
          fontSize={50}
          textAlign={"start"}
          marginTop={3}
        >
          {t("haveEverythingYouNeed")}
        </Typography>

        <Typography
          color={"gray"}
          fontSize={16}
          textAlign={"start"}
          marginTop={3}
          paddingBottom={5}
        >
          {t("servicesHighlightsDescription")}
        </Typography>
      </Grid>

      <Grid sm={12} lg={7}>
        <Grid container>
          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <IntegrationInstructionsOutlinedIcon fontSize={"large"} />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("nativeWeb")}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <WebOutlinedIcon fontSize={"large"} />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("pwaWeb")}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <Box
                component={"img"}
                src={WordpressIcon}
                width={"35px"}
                height={"35px"}
              />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("wordpress")}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <Box
                component={"img"}
                src={ShopifyStore}
                width={"35px"}
                height={"35px"}
              />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("shopifyStores")}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <Box component={"img"} src={SEO} width={"35px"} height={"35px"} />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("seoConsulting")}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <Box
                component={"img"}
                src={MobileIcon}
                width={"35px"}
                height={"35px"}
              />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("mobile")}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <DesktopWindowsOutlinedIcon fontSize={"large"} />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("desktop")}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={6} sm={3} item padding={0.5}>
            <Box padding={2} sx={{}}>
              <GamesOutlinedIcon fontSize={"large"} />

              <Typography textAlign={"center"} fontWeight={500}>
                {t("games")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
