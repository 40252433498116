import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Chip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CardProps {
  packageName: string;
  description: string;
  price: number;
  maintenancePrice: number;
  features: string[];
  licenceUrl: string;
  stripeProductUrl: string;
  isPopular?: boolean;
}

export default function PricingCard(props: CardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card
      sx={{
        borderRadius: "15px",
        padding: "20px",
        height: "100%",
        boxShadow:
          "0px 6px 6px -3px rgba(0, 0, 0, 0.02), 0px 10px 14px 1px rgba(0, 0, 0, 0.02), 0px 4px 18px 3px rgba(0, 0, 0, 0.02)",
        border: "1px solid rgb(230, 230, 230)",
      }}
    >
      <CardContent>
        {props.isPopular !== undefined && props.isPopular ? (
          <Box display={"flex"} justifyContent={"start"}>
            <Chip
              label="Popular"
              sx={{
                fontSize: "10px",
                fontWeight: 600,
                paddingLeft: "10px",
                paddingRight: "10px",
                backgroundColor: `rgba(132, 161, 255, 0.5)`,
                color: `${theme.palette.secondary.main}`,
              }}
            />
          </Box>
        ) : null}
        <Box marginTop={1} display={"flex"} justifyContent={"space-between"}>
          <Typography
            textAlign={"start"}
            sx={{ fontSize: 20, fontWeight: 500 }}
            gutterBottom
          >
            {props.packageName}
          </Typography>

          <Typography
            sx={{ fontSize: 25 }}
            fontWeight={600}
            color="text.secondary"
            gutterBottom
          >
            {`€${props.price}`}
          </Typography>
        </Box>

        <Typography textAlign={"end"} fontSize={12}>
          Then <span>{`€${props.maintenancePrice} / month`}</span>
        </Typography>

        <Typography
          marginTop={2}
          textAlign={"start"}
          color="mediumLightText"
          fontSize={14}
        >
          {props.description}
        </Typography>

        <Box marginTop={5}>
          {props.features.map((e) => (
            <Box display={"flex"}>
              <CheckCircleOutlineRoundedIcon />

              <Typography
                sx={{ mb: 1.5 }}
                marginLeft={1}
                color="text.secondary"
                textAlign={"start"}
              >
                {e}
              </Typography>
            </Box>
          ))}
        </Box>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            marginTop: "24px",
            fontSize: "12px",
            fontWeight: 600,
          }}
          color="secondary"
          onClick={() => {
            window.open(props.stripeProductUrl);
          }}
        >
          {t("buyPackage")}
        </Button>

        {/*<Button
          variant="text"
          sx={{
            width: "100%",
            marginTop: "12px",
            fontSize: "12px",
            fontWeight: 600,
            color: `${theme.palette.mediumLightText}`,
          }}
        >
          {t("moreInfo")} <ChevronRightRoundedIcon />
        </Button>*/}
      </CardContent>
    </Card>
  );
}
