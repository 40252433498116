import { Box, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContactForm from "./contact/ContactForm";
import ContactOptions from "./contact/ContactOptions";

export default function Contact() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      id="contact"
      component={"section"}
      marginBottom={20}
      paddingLeft={{ xs: 0, md: 10, lg: 20 }}
      paddingRight={{ xs: 0, md: 10, lg: 20 }}
      display={"flex"}
      justifyContent={"center"}
      marginTop={10}
      marginLeft={4}
      marginRight={4}
    >
      <Box
        maxWidth={"800px"}
        width={"800px"}
        padding={7}
        sx={{
          borderRadius: "15px",
          border: `1px dashed ${theme.palette.lightOutline}`,
        }}
      >
        <ContactForm />

        <ContactOptions />
      </Box>
    </Box>
  );
}
