import {
  Box,
  Button,
  Grid,
  List,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";

import GenericHeadphones from "../assets/generic_headphones.png";
import GenericEarphone from "../assets/generic_earphone.png";
import GenericSmartWatch from "../assets/generic_smart_watch.png";
import GenericLaptop from "../assets/generic_laptop.png";
import React from "react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useTranslation } from "react-i18next";

interface Props {
  window?: () => Window;
}

export default function BusinessPageTemplate(props: Props) {
  const theme = useTheme();

  const { t } = useTranslation();

  const navItems = ["home", "shop", "aboutUs", "blog", "contactUs"];

  return (
    <Grid
      container
      sx={{
        padding: "20px",
      }}
    >
      <Box
        display={{
          xs: "none",
          sm: "block",
        }}
      >
        <List
          sx={{
            display: "flex",
            fontSize: "10px",
          }}
        >
          <Typography
            color={"secondary"}
            fontWeight={600}
            fontSize={14}
            marginRight={4}
            key={"store"}
          >
            {t("yourStore")}
          </Typography>
          {navItems.map((item) => (
            <Button
              key={item}
              sx={{
                fontSize: "10px",
                marginRight: "1px",
                marginLeft: "1px",
                padding: "0px",
              }}
              variant="text"
            >
              {t(item)}
            </Button>
          ))}
        </List>
      </Box>

      <Box
        display={{
          xs: "block",
          sm: "none",
        }}
        marginBottom={1}
      >
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button variant="text" {...bindTrigger(popupState)}>
                <MenuRoundedIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                {navItems.map((item) => (
                  <MenuItem key={item} onClick={popupState.close}>
                    {t(item)}
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </Box>
      <Grid
        item
        xs={12}
        sx={{
          background: `${theme.palette.mediumBackground}`,
          borderRadius: "15px",
          padding: "20px",
        }}
      >
        <Typography textAlign={"start"}>{t("powerful")}</Typography>

        <Typography
          textAlign={"start"}
          fontSize={40}
          fontWeight={600}
          marginTop={0}
        >
          {t("wireless")}
        </Typography>

        <Typography
          textAlign={"start"}
          fontSize={{
            xs: 35,
            sm: 40,
            md: 60,
            lg: 70,
          }}
          fontWeight={600}
          marginTop={2}
          color={"white"}
        >
          {t("headphone")}
        </Typography>

        <Box
          component={"img"}
          src={GenericHeadphones}
          height={250}
          sx={{
            marginTop: "-150px",
            marginBottom: "32px",
          }}
        />

        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"-50px"}
          marginBottom={"20px"}
        >
          <Button
            color={"secondary"}
            variant={"contained"}
            sx={{
              borderRadius: "100px",
              fontSize: "8px",
              fontWeight: 600,
            }}
          >
            {t("shopByCategory")}
          </Button>

          <Box>
            <Typography textAlign={"end"} fontSize={8} fontWeight={600}>
              {t("description")}
            </Typography>

            <Typography textAlign={"end"} fontSize={8} marginTop={"5px"}>
              {t("amazingHeadphones")}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid container marginTop={2.5}>
        <Grid item xs={6} sm={3} paddingRight={1}>
          <Box
            sx={{
              background: `${theme.palette.mediumBackground}`,
              borderRadius: "15px",
              height: "170px",
              backgroundColor: "#3d3d3d",
              padding: "10px",
              overflow: "clip",
            }}
          >
            <Typography
              textAlign={"start"}
              color={"white"}
              fontSize={8}
              marginTop={1}
            >
              {t("new")}
            </Typography>

            <Typography
              textAlign={"start"}
              color={"white"}
              fontSize={10}
              fontWeight={600}
              marginTop={0.5}
            >
              {t("super")}
            </Typography>

            <Typography
              textAlign={"start"}
              fontSize={20}
              fontWeight={600}
              marginTop={2}
              color={"white"}
            >
              {t("wear")}
            </Typography>

            <Box
              component={"img"}
              src={GenericSmartWatch}
              height={150}
              sx={{
                marginTop: "-70px",
                marginBottom: "20px",
              }}
            />

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"start"}
              marginTop={"-85px"}
              marginBottom={"20px"}
            >
              <Button
                color={"secondary"}
                variant={"contained"}
                sx={{
                  borderRadius: "100px",
                  fontSize: "8px",
                  fontWeight: 600,
                }}
              >
                {t("browse")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          paddingRight={{
            xs: 0,
            sm: 1,
          }}
          paddingLeft={1}
        >
          <Box
            sx={{
              background: `${theme.palette.mediumBackground}`,
              borderRadius: "15px",
              height: "170px",
              backgroundColor: `${theme.palette.lightGrayText}`,
              padding: "10px",
              overflow: "clip",
            }}
          >
            <Typography
              textAlign={"start"}
              color={"white"}
              fontSize={8}
              marginTop={1}
            >
              {t("enjoy")}
            </Typography>

            <Typography
              textAlign={"start"}
              color={"white"}
              fontSize={10}
              fontWeight={600}
              marginTop={0.5}
            >
              {t("with")}
            </Typography>

            <Typography
              textAlign={"start"}
              fontSize={20}
              fontWeight={600}
              marginTop={2}
              color={"white"}
            >
              {t("earhphone")}
            </Typography>

            <Box
              component={"img"}
              src={GenericEarphone}
              height={200}
              sx={{
                marginTop: "-60px",
                marginBottom: "2px",
              }}
            />

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"start"}
              marginTop={"-125px"}
              marginBottom={"20px"}
            >
              <Button
                color={"secondary"}
                variant={"contained"}
                sx={{
                  borderRadius: "100px",
                  fontSize: "8px",
                  fontWeight: 600,
                }}
              >
                {t("browse")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          paddingLeft={{
            xs: 0,
            sm: 1,
          }}
          marginTop={{
            xs: 2.5,
            sm: 0,
          }}
        >
          <Box
            sx={{
              background: `${theme.palette.mediumBackground}`,
              borderRadius: "15px",
              height: "170px",
              backgroundColor: `${theme.palette.secondary.main}`,
              padding: "20px",
              overflow: "clip",
            }}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                textAlign={"start"}
                color={"white"}
                fontSize={8}
                marginTop={1}
              >
                {t("trending")}
              </Typography>

              <Typography
                textAlign={"start"}
                color={"white"}
                fontSize={10}
                fontWeight={600}
                marginTop={0.5}
              >
                {t("device")}
              </Typography>

              <Typography
                textAlign={"start"}
                fontSize={20}
                fontWeight={600}
                marginTop={2}
                color={"white"}
              >
                {t("laptop")}
              </Typography>

              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"start"}
                marginTop={"0px"}
                marginBottom={"10px"}
              >
                <Button
                  variant={"contained"}
                  sx={{
                    borderRadius: "100px",
                    fontSize: "8px",
                    fontWeight: 600,
                    marginTop: "16px",
                    backgroundColor: "white",
                  }}
                >
                  {t("browse")}
                </Button>
              </Box>
            </Box>

            <Box component={"img"} src={GenericLaptop} height={150} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
